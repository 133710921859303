import Vue from 'vue'
import Parse from 'parse'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Snotify from 'vue-snotify';
import VueSwal from 'vue-swal'
import moment from 'moment'
import VueCurrencyFilter from 'vue-currency-filter'

Vue.config.productionTip = false

Parse.initialize(process.env.VUE_APP_PARSE_SERVER_APPLICATION_ID, process.env.VUE_APP_JS_KEY) // process.env.VUE_APP_JS_KEY
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL

moment.locale('es');
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(Snotify, {
  toast: {
    timeout: 4000
  },
  global: {
    preventDuplicates: true
  }
})

Vue.use(VueSwal)

Vue.use(VueCurrencyFilter, {
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
