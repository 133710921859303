import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalSendSmsCall: false,
    menuAdminActive: false
  },
  getters: {
    getModalSendSmsCall: state => {
      return state.modalSendSmsCall
    },
    getMenuAdminActive: state => {
      return state.menuAdminActive
    },
  },
  mutations: {
    setModalSendSmsCall(state, value) {
      state.modalSendSmsCall = value;
    },
    setMenuAdminActive(state, value) {
      state.menuAdminActive = value;
    }

  },
  actions: {
  },
  modules: {
  }
})
