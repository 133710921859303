import Vue from 'vue'
import VueRouter from 'vue-router'
import Parse from 'parse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/auth',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/control',
    name: 'Control',
    component: () => import('../views/Control.vue'),
    meta: { requiresAuth: true, role: ['Administrador', 'Operador'] }
  },
  {
    path: '/epco/response',
    name: 'ePaycoInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ePaycoResponse.vue'),
    meta: { requiresAuth: false, role: ['Administrador', 'Operador'] }
  },
  {
    path: '/my-recharges',
    name: 'myrecharges',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyRecharges.vue'),
    meta: { requiresAuth: true, role: ['Administrador', 'Operador'] }
  },
  {
    path: '/masive/sms',
    name: 'MasiveSMS',
    component: () => import('../views/MasiveSMS.vue'),
    meta: { requiresAuth: true, role: ['Administrador', 'Operador'] }
  },
  {
    path: '/messages/:transaction_id',
    name: 'messages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportTransaction.vue'),
    meta: { requiresAuth: true, role: ['Administrador', 'support'] }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: { requiresAuth: false, role: ['Administrador', 'Operador'] }
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const loggedIn = Parse.User.current();
  const roles_array = to.meta.role;

  if (requiresAuth && !loggedIn) {
    return next('/');
  }

  if (roles_array) {
    /*const isRoleUser = await isRole(roles_array);
    //console.log(roles_array);
    //console.log(isRoleUser);
    if(!isRoleUser){
      alert('No tienes los permisos suficientes. ' + roles_array.join(', '))
      return next('/');
    }*/

  }
  next();
})

export default router
