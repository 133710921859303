<template>
  <v-app class="myFont">
    <v-app-bar
      v-if="$store.state.menuAdminActive"
      app
      color="#030826"
      dark
    >
      <v-app-bar-nav-icon v-if="auth" @click="active = !active" dark></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
          alt="Vuetify Logo"
          class="mr-2"
          contain
          :src="require('./assets/logo.png')"
          transition="scale-transition"
          width="100"
        />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="auth"
        rounded
        dark
        outlined
        class="d-none d-sm-flex"
        @click="logOut()"
      >
        <v-icon left>mdi-close</v-icon> Cerrar sesión
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer
      v-if="auth"
      v-model="active"
      app
      temporary
    >
      <v-list-item>
        <!--<v-list-item-avatar>
          <v-img :src="require('./assets/user.png')"></v-img>
        </v-list-item-avatar>-->

        <v-list-item-content>
          <v-list-item-title v-if="auth">{{auth.attributes.name}}</v-list-item-title>
          <v-list-item-title v-else> Falso positivo </v-list-item-title>
          <v-list-item-title class="body-2" v-if="auth">{{auth.attributes.email}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item to="/control" @click.stop="active = false">
          <v-list-item-icon>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Panel de control</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/my-recharges" @click.stop="active = false">
          <v-list-item-icon>
            <v-icon>mdi-account-cash</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ver recargas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$store.state.modalSendSmsCall = true" @click.stop="active = false">
          <v-list-item-icon>
            <v-icon>mdi-phone-message</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Enviar SMS / Llamada</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/masive/sms" @click.stop="active = false">
          <v-list-item-icon>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Enviar SMS Masivos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logOut()" @click.stop="active = false">
          <v-list-item-icon>
            <v-icon>mdi-close</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <vue-snotify></vue-snotify>
    <!-- Dialogo Dialogo Send Call or SMS -->
    <v-dialog
      v-if="auth"
      v-model="$store.state.modalSendSmsCall"
      width="500"
      persistent
      scrollable
    >
    <v-form
      ref="formcall"
      v-model="validCallSMS"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Enviar SMS / Llamada </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12" class="pa-0 ma-0">
             <v-radio-group 
              v-model="radioGroup" 
              row
              hide-details
              style="padding-left: 10px;">
              <v-radio
                label="Enviar SMS"
                value="1"
              ></v-radio>
              <v-radio
                label="Enviar Llamada"
                value="2"
              ></v-radio>
            </v-radio-group>
            </v-col>

            <v-col cols="12">
              <!--
              <v-text-field
                outlined
                v-model.trim="phone"
                :error-messages="phoneErrors"
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                label="Teléfono"
                placeholder="Escriba el teléfono del destinatario"
                required
                hide-details
              ></v-text-field>
              -->
                <VuePhoneNumberInput 
                    v-model.trim="phone"
                    default-country-code="CO"
                    :translations="{
                        countrySelectorLabel: 'Código país',
                        countrySelectorError: 'Seleccione un país',
                        phoneNumberLabel: 'Número de telefono',
                        example: 'Ejemplo :'
                    }"
                    required
                    @update="validPhonePayload"
                />
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                :value="body"
                @change="v => body = v"
                :rules="bodyRules"
                label="Mensaje"
                placeholder="Escriba el mensaje"
                counter="160"
              ></v-textarea>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="$store.state.modalSendSmsCall = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            outlined
            @click="sendCallSMS()"
            :disabled="phone ? false : true"
          >
           <v-icon left>mdi-send</v-icon> Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo Send Call or SMS -->
    
  </v-app>
</template>

<script>
import Parse from 'parse'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: 'App',

  components: {
    VuePhoneNumberInput
  },

  data: () => ({
    auth: Parse.User.current(),
    active: false,
    validCallSMS: true,
    radioGroup: '1',
    phone: '',
    body: '',
    payload: '',
    companie: '',
    bodyRules: [
      v => !!v || 'Mensaje es requerido',
      v => !!v && v.length <= 160 || 'El mensaje no debe sobrepasar los 160 caracteres'
    ],
  }),

  mounted() {
    this.init()
  },

  methods: {
    async init(){
      if (Parse.User.current()) {
        try {
            let params = {
                id: Parse.User.current().id
            }

            let companie = await Parse.Cloud.run("getCompany", params)
            this.companie = companie
            //console.log(companie.id);

        } catch (error) {
            this.$snotify.error(error.message , 'Empresa', {
                timeout: 4000,
                preventDuplicates: true
             }); 
        }
      }
        },

    logOut() {
      /* eslint-disable */
      /*OneSignal.push(function() {
        OneSignal.removeExternalUserId();
      });*/
      Parse.User.logOut();
      window.location.reload()
    },
    commingSoon(){
      this.$snotify.info(`Muy pronto esta opción estará habilitada` , 'Recargas', {
          timeout: 4000,
          preventDuplicates: true
      });
    },

    async sendCallSMS(){
            if (this.$refs.formcall.validate()) {

                if(!this.payload.isValid) {
                    this.$snotify.error(`El celular es inválido` , 'Llamada / SMS', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                    return;
                }

                if (!this.phone) {
                    this.$snotify.error(`No se ha enviado el teléfono` , 'Llamada / SMS', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                    return;
                }

                if (this.radioGroup == '1') {
                    try {
                        let params = {
                            id: this.companie.id,
                            phone: `${this.payload.countryCallingCode}${this.payload.nationalNumber}`,
                            message: this.body
                        };
    
                        await Parse.Cloud.run("sendSMS", params);
                        
                        this.$snotify.success(`SMS enviado correctamente` , 'Envío SMS', {
                            timeout: 4000,
                            preventDuplicates: true
                        });
    
                        this.$store.state.modalSendSmsCall = false
                        
                    } catch (error) {
                        this.$snotify.error(`${error}` , 'SMS', {
                            timeout: 4000,
                            preventDuplicates: true
                        });
                    }
                    
                }else if(this.radioGroup == '2'){
                    try {
                        let params = {
                            id: this.companie.id,
                            phone: `${this.payload.countryCallingCode}${this.payload.nationalNumber}`,
                            message: this.body
                        };
    
                        await Parse.Cloud.run("sendCall", params);
                        
                        this.$snotify.success(`Llamada enviada correctamente` , 'Envío Llamada', {
                            timeout: 4000,
                            preventDuplicates: true
                        });
    
                        this.$store.state.modalSendSmsCall = false
                        
                    } catch (error) {
                        this.$snotify.error(`${error}` , 'Llamada', {
                            timeout: 4000,
                            preventDuplicates: true
                        });
                    }
                }
                
            }else{
                this.$snotify.error(`Compruebe todo los campos antes de enviar el formulario` , 'SMS / Llamada', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
      },
      validPhonePayload(payload){
        this.payload = payload
      },
  },
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
</style>

<style lang="css">

  .myFont {
    font-family: 'Montserrat' !important;
  }

  .swal-modal {
      font-family: Helvetica Neue, Arial, sans-serif;
  }

</style>